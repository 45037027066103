import request from '@/utils/request'

const getArticleList = (params) => {
  return request({
    url: '/open/article',
    method: 'get',
    params: params
  })
}

export { getArticleList }
